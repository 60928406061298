@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
}




.ant-notification-notice,
.ant-notification-notice-error {
  background-color: black !important;
  border-radius: 8px;
}
.ant-notification-notice-message,
.ant-notification-notice-close {
  color: white !important;
}
.ant-notification-notice-description {
  color: #aaa !important;
}

.ant-menu-submenu-selected
.ant-menu-title-content,
.ant-menu-item-selected,
.ant-btn-primary
{
  color: black !important
}
